<template>
  <div class="homepage">
    <base-header></base-header>
    <div class="info">
      <div class="user">
        <span class="name">{{ user.realname }}</span>
        <span class="class">{{ className }}</span>
      </div>
      <el-button 
        size="mini" 
        icon="iconfont icon-tuichu" 
        @click="logout" 
        class="logout"
      >退出
      </el-button>
    </div>
    <div class="main">
      <home-content class="menu"></home-content>
    </div>
    <base-footer></base-footer>
  </div>
</template>

<script>
import BaseHeader from "../components/BaseHeader.vue";
import BaseFooter from "../components/BaseFooter.vue";
import NavMenu from "../components/NavMenu.vue";
import HomeContent from "../components/HomeContent.vue";
import { logout } from '@/api/login';

export default {
  components: {
    BaseHeader,
    BaseFooter,
    NavMenu,
    HomeContent,
  },
  data() {
    return {
      user: JSON.parse(localStorage.getItem("ss-userInfo")),
      className:localStorage.getItem("ss-className")
    };
  },
  mounted(){
  },
  methods: {
    logout() {
      this.$confirm("确认退出登录吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        customClass: "messageBox",
      }).then(() => {
        logout().then(res => {
          if (res.success) {
            if(localStorage.getItem('disabledSchool')){
              this.$router.push(`/login?school=${localStorage.getItem('disabledSchool')}`);
            }else{
              this.$router.push('/login');
            }
            localStorage.removeItem('ss-token');
            localStorage.removeItem('ss-userInfo');
            localStorage.removeItem('ss-className');
            localStorage.removeItem('paperId');
            localStorage.removeItem("disabledSchool")
          } else {
            this.$message({
              message: res.message,
              type: 'warning',
            })
          }
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.homepage{
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.info{
  width: 1300px;
  text-align: right;
  margin: 0 auto;
  .user{
    display: inline-block;
    background: #e9eff9;
    border-radius: 6px;
    padding: 3px 16px;
    margin-right: 10px;
    vertical-align: middle;
    .name {
      color: #ff7700;
      font-size: 18px;
      font-weight: bold;
      margin-right: 9px;
    }
    .class {
      color: #0065fd;
      font-size: 16px;
    }
  }
}
.main{
  flex: 1;
  position: relative;
}
.menu{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}
</style>