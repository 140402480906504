<template>
  <div class="content">
    <el-row :gutter="30" v-if="!disabledSchool">
      <el-col :span="6" v-for="item in navData" :key="item.title">
        <div class="item" :class="{'active':item.status == 1}" @click="goPage(item)">
          <div class="img-box"></div>
          <div class="text">{{ item.name }}</div>
          <div class="btn">进入系统</div>
        </div>
      </el-col>
    </el-row>
    <div class="wrap" v-if="disabledSchool">
      <template v-for="item in navData" >
        <div class="item"
          v-if="item.status == 1"
          :key="item.title"
          :class="{'active':item.status == 1}"
          @click="goPage(item)"
        >
          <div class="img-box"></div>
          <div class="text">{{ item.name }}</div>
          <div class="btn">进入系统</div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import { stumenu } from '@/api/user';
export default {
  data() {
    return {
      navData: [],
      disabledSchool:""
    };
  },
  mounted() {
    this.fetchData();
    this.disabledSchool = localStorage.getItem('disabledSchool')
  },
  methods: {
    fetchData() {
      stumenu({
        schoolId: localStorage.getItem('ss-schoolId'),
      }).then(res => {
        this.navData = res.result;
        let obj={
          StuQuality:'/studentEvaluate',
          EvatQuality:'/teacher',
          Question:'/MyQuestion',
          PsychEva:''
        }
        this.navData.forEach(item=>{
          item.path = obj[item.code]
        })
      });
    },
    goPage(item) {
      console.log(item, '====item');
      if (item.status == 1) {
        this.$router.push(item.path);
        localStorage.setItem('menuName',item.name)
        if(item.code==='EvatClass'){
          window.open("https://xp.hxpxxy.cn/card-web/")
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.content {
  width: 1300px;
  margin: 0 auto;
  .item {
    height: 368px;
    background: #fff;
    box-shadow: 0px 15px 34px 17px rgba(224, 231, 253, 0.63);
    border-radius: 10px;
    padding: 36px 60px;
    box-sizing: border-box;
    cursor: pointer;
    .img-box {
      width: 105px;
      height: 105px;
      margin: 0 auto;
      background: #f3f3f3;
      margin: 10px auto 20px;
      img {
        width: 100%;
      }
    }
    .text {
      height: 76px;
      font-size: 28px;
      color: #999;
      text-align: center;
      overflow: hidden;
      margin-bottom: 30px;
      font-weight: 600;
    }
    .btn {
      font-size: 18px;
      color: #999;
      text-align: center;
      cursor: pointer;
    }
  }
  .el-col:nth-child(1) .item .img-box {
    background: url(~@/assets/img/homepage/nav-1.png) no-repeat center center;
    background-size: 100%;
  }
  .el-col:nth-child(2) .item .img-box {
    background: url(~@/assets/img/homepage/nav-2.png) no-repeat center center;
    background-size: 100%;
  }
  .el-col:nth-child(3) .item .img-box {
    background: url(~@/assets/img/homepage/nav-3.png) no-repeat center center;
    background-size: 100%;
  }
  .el-col:nth-child(4) .item .img-box {
    background: url(~@/assets/img/homepage/nav-4.png) no-repeat center center;
    background-size: 100%;
  }
  .item.active {
    .text {
      color: #333;
    }
    .btn {
      color: #1578fa;
    }
  }
  .item.active:hover {
    transition: 0.3s;
    transform: translateY(-20px);
    .text {
      color: #1578fa;
    }
  }
}
.wrap{
  display: flex;
  margin: 0 -20px;
  // justify-content: center;
  .item{
    box-sizing: border-box;
    width: calc(100% / 4 - 40px);
    margin: 0 20px;
  }
  .item:nth-child(1) .img-box {
    background: url(~@/assets/img/homepage/nav-1.png) no-repeat center center;
    background-size: 100%;
  }
  .item:nth-child(2) .img-box {
    background: url(~@/assets/img/homepage/nav-2.png) no-repeat center center;
    background-size: 100%;
  }
  .item:nth-child(3) .img-box {
    background: url(~@/assets/img/homepage/nav-3.png) no-repeat center center;
    background-size: 100%;
  }
  .item:nth-child(4) .img-box {
    background: url(~@/assets/img/homepage/nav-4.png) no-repeat center center;
    background-size: 100%;
  }
}
</style>
