<template>
  <div class="base-header">
    <div class="container">
      <a href="/" class="logo">
        <!-- TODO 城市学院临时使用 -->
        <template v-if="code == 'csxy'">
          <img src="@/assets/img/homepage/csxy-logo.png" alt=""  style="width: 120px;height: 120px"/>
          <span class="title">多元化评价系统</span>
        </template>
        <template v-else-if="code == 'nazjzx'">
          <img src="@/assets/img/homepage/nazjzx-logo.png" alt=""  style="width: 120px;height: 120px"/>
          <span class="title">职业教育中心综合素质素养发展平台</span>
        </template>
        <template v-else>
          <img src="@/assets/img/homepage/logo1.png" alt=""  style="width: 120px;"/>
          <span class="title">职业教育评价服务云平台</span>
        </template>
      </a>
    </div>
  </div>
</template>
<script>
import { wechat } from "@/api/user";
export default {
  name: "BaseHeader",
  data() {
    return {
      code: localStorage.getItem('disabledSchool')
    };
  },
  created() {
    setTimeout(() => {
      let mycode = this.$route.query.code;
      let params = {
        code: mycode,
      };
      if (mycode) {
        wechat(params).then((res) => {
          if (res.success) {
            this.$message({
              message: res.message,
              type: "success",
            });
            this.$router.push(this.$route.path);
          } else {
            this.$message({
              message: res.message,
              type: "warning",
            });
          }
        });
      } else {
      }
    }, 100);
  }
};
</script>
<style lang="scss" scoped>
.base-header {
  height: 218px;
  background: url("~@/assets/img/homepage/head1.png") no-repeat center;
  background-size: cover;
  .container {
    width: 1300px;
    padding-top: 54px;
    box-sizing: border-box;
    margin: 0 auto;
    .logo {
      display: flex;
      align-items: center;
      .title {
        font-size: 48px;
        color: #fff;
        margin-left: 10px;
        /*vertical-align: super;*/
      }
    }
  }
}
</style>
